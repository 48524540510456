<template>
  <a-drawer 
		class="culture-add" 
		title="企业文化相关" 
		:maskClosable="false"
		@close="onClose" 
		:width="720" 
		:visible="cultureAddVisiable"
	>
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="企业文化名称" prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="企业文化类型" prop="cultureType" v-bind="formItemLayout">
        <a-select v-model="form.cultureType">
          <a-select-option v-for="item in cultureTypeArr" :key="item.dictId" :value="item.dictId">{{item.valuee}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="企业文化内容" prop="content" v-bind="formItemLayout">
        <a-textarea placeholder="企业文化内容" v-model="form.content" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="封面图" prop="image" v-bind="formItemLayout">
        <UploadImgs :path="IMAGE_CULTURE" :file-list="fileList" :limit="1" @_change="handleUploadImgChange"></UploadImgs>
        <a-tag color="orange">建议图片尺寸：400*460px</a-tag>
      </a-form-model-item>
      <a-form-model-item label="附件详情" v-bind="formItemLayout">
        <UploadEnclosure :path="PDF_CULTURE" :enclosure-list="enclosureList" @_change="handleUploadEnclosureChange"></UploadEnclosure>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import {IMAGE_CULTURE, PDF_CULTURE} from "../../../utils/upload-path-map"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"
import UploadEnclosure from "../../../components/upload-enclosure/UploadEnclosure"

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}

export default {
  name: "CultureAdd",
  components: {UploadEnclosure, UploadImgs},
  props: {
    cultureAddVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      PDF_CULTURE,
      IMAGE_CULTURE,
      enclosureList: [],
      fileList: [],
      starCulture: false,
      cultureTypeArr: [],
      form: {
        title: undefined,
        cultureType: undefined,
        content: undefined,
        image: undefined,
        enclosure: undefined
      },
      rules: {
        title: [
          { required: true, message: '企业文化名称不能为空', trigger: 'blur' }
        ],
        cultureType: [
          { required: true, message: '企业文化类型不能为空', trigger: 'change' }
        ],
        content: [
          { required: true, message: '企业文化内容不能为空', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '企业文化封面不能为空', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    this.cultureTypeArr = this.$store.state.dict.dicts.company_culture_culture_type;
    console.log("this.cultureTypeArr ==>", this.cultureTypeArr);
  },
  methods: {
    handleStarCultureChange(val) {
      this.starCulture = val;
      this.form.isStar = val?'1':'0';
    },
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.enclosureList = [];
      this.fileList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log("要创建的数据", {...this.form})
          this.$post("company-culture", {...this.form}).then(r => {
            console.log("添加企业文化相关", r.data);
            this.reset();
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    handleUploadImgChange(fileList, urls) {
      console.log("上传图片组件传过来的值", urls);
      // this.form.image = JSON.stringify(urls);
      this.form.image = urls.length?urls[0]['filePath']:'';
      this.fileList = fileList;
    },
    handleUploadEnclosureChange(list, urls) {
      // console.log("上传附件的返回值===>", list, urls);
      this.enclosureList = list;
      // this.form.enclosure = JSON.stringify(urls);
      this.form.enclosure = urls.length?urls[0]['filePath']:'';
    }
  }
}
</script>