<template>
  <a-card class="card-area">
    <div class="operator">
      <a-button @click="addCulture" type="primary" ghost>新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <a-table 
      ref="TableInfo" 
      :scroll="{ x: 900 }"
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" 
      @change="handleTableChange" 
      :pagination="pagination" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :data-source="dataSource"
      :rowKey="(record) => record.id"
    >
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editCulture(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <culture-edit ref="cultureEdit" @close="handleCultureEditClose" @success="handleCultureEditSuccess" :cultureEditVisiable="cultureEditVisiable"></culture-edit>
    <culture-add ref="cultureAdd" @close="handleCultureAddClose" @success="handleCultureAddSuccess" :cultureAddVisiable="cultureAddVisiable"></culture-add>
  </a-card>
</template>

<script>
import CultureAdd from "./CultureAdd"
import CultureEdit from "./CultureEdit"

const columns = [
  // {
  //   title: "序号",
  //   dataIndex: "key",
  //   width: 100
  // },
  {
    title: "文化标题",
    dataIndex: "title",
    width: 200
    // ellipsis: true
  },
  {
    title: "文化内容",
    dataIndex: "content",
    // ellipsis: true
    width: 600
  },
  {
    title: "文化类型",
    dataIndex: "cultureTypeName",
    // ellipsis: true
    width: 150
  },
  // {
  //   title: "封面图",
  //   dataIndex: "image",
  //   // ellipsis: true
  // },
  // {
  //   title: "附件",
  //   dataIndex: "enclosure",
  //   // ellipsis: true
  // },
  {
    title: "发布时间",
    dataIndex: "updateTime",
    // ellipsis: true
    width: 150
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
    width: 70
  }
];

export default {
  components: {
    CultureAdd,
    CultureEdit
  },
  data() {
    return {
      dataSource: [],
      columns,
      cultureTypeArr: [],
      selectedRowKeys: [],
      cultureAddVisiable: false,
      cultureEditVisiable: false,
      queryParams: {},
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
    };
  },
  mounted() {
    this.cultureTypeArr = this.$store.state.dict.dicts.company_culture_culture_type;
    this.fetch();
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }

      this.$get("company-culture", {
        ...params
      }).then((r) => {
        console.log("获取到的公司文化列表", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        let rows = r.data.rows;
        if(rows) {
          for (const key in rows) {
            let cultureType = this.cultureTypeArr.find(item => item.dictId == rows[key]['cultureType'])["valuee"];
            rows[key]['cultureTypeName'] = rows[key]['cultureType']?cultureType:undefined;
          }
          this.dataSource = rows;
        }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      });
    },
    addCulture() {
      this.cultureAddVisiable = true;
    },
    handleCultureAddSuccess() {
      this.cultureAddVisiable = false;
      this.$message.success("企业文化添加成功！");
      this.fetch();
    },
    handleCultureAddClose() {
      this.cultureAddVisiable = false;
    },
    editCulture(record) {
      this.cultureEditVisiable = true;
      this.$refs.cultureEdit.setFormFields(record);
    },
    handleCultureEditSuccess() {
      this.$message.success("企业文化修改成功！");
      this.cultureEditVisiable = false;
      this.fetch();
    },
    handleCultureEditClose() {
      this.cultureEditVisiable = false;
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`company-culture/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("企业文化删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>

